import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import api from "services/api";
import { useAuth } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faFileCirclePlus, faFilter, faHandHoldingDollar, faHandshake, faHeart, faHouse, faParachuteBox, faPenNib, faPeopleArrows, faShopLock, faTag, faUser, faUserGear, faUserSecret, faUserShield } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {
  const navigate = useNavigate();
  const { user, removeToken } = useAuth();
  const isLoggedIn = localStorage.getItem("user");
  const userData = useSelector((state) => state.profile.profileDataRedux);
  const [profile, setProfile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [dashboardMenu, setDashBoardMenu] = useState("");
  const [accountMenu, setAccountMenu] = useState("");

  const handleLogout = () => {
    api
      .post("/logout", {})
      .then((res) => {
        removeToken();
        navigate("/");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);
  const handleDashboardMenu = () => {
    if (dashboardMenu === "open") {
      setDashBoardMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setDashBoardMenu("");
      }, 200);
    } else {
      setDashBoardMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setDashBoardMenu("open");
      }, 200);
    }
  };
  
  const handleAccountMenu = () => {
    if (accountMenu === "open") {
      setAccountMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setAccountMenu("");
      }, 200);
    } else {
      setAccountMenu("menu-item-animating menu-item-closing");
      setTimeout(() => {
        setAccountMenu("open");
      }, 200);
    }
  };
  return (
    <nav
      className="layout-navbar shadow-none py-0"
      style={{ position: "sticky", top: "0px" }}
    >
      <div className="container">
        <div
          className="navbar navbar-expand-lg landing-navbar px-3 px-md-4"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
            <button
              className="navbar-toggler border-0 px-0 me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti ti-menu-2 ti-sm"></i>
            </button>
          </div>
          <div
            className="collapse navbar-collapse landing-nav-menu"
            id="navbarSupportedContent"
          >
            <button
              className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti ti-x ti-sm" />
            </button>
            <ul className="navbar-nav me-auto ">
              <li
                className={`menu-item ${pathname === "/dashboard" && "active"}`}
              >
                <Link to="/dashboard" className="menu-link ">
                  <div data-i18n="Dashboard">
                    {" "}
                    <FontAwesomeIcon
                      icon={faHouse}
                      size="xs"
                      className="me-2"
                    />
                    Dashboard
                  </div>
                </Link>
              </li>
              <li
                className={`menu-item ${
                  pathname === "/showplansall" ||
                  pathname.startsWith("/selectedPlan") ||
                  pathname.startsWith("/plansEdit") ||
                  pathname.startsWith("/addPlan")
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/showplansall" className="menu-link ">
                  <div data-i18n="Dashboard">
                    {" "}
                    <FontAwesomeIcon
                      icon={faHeart}
                      size="xs"
                      className="me-2"
                    />
                    Plans
                  </div>
                </Link>
              </li>
              <li
                className={`menu-item ${
                  pathname === "/memberships" ||
                  pathname.startsWith("/membershipDetails") ||
                  pathname.startsWith("/userDetailsEdit") ||
                  pathname.startsWith("/addUser")
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/memberships" className="menu-link ">
                  <div data-i18n="Dashboard">
                    {" "}
                    <FontAwesomeIcon
                      icon={faPeopleArrows}
                      size="xs"
                      className="me-2"
                    />
                    Users
                  </div>
                </Link>
              </li>

              <li
                className={`menu-item ${
                  pathname === "/corperators" ||
                  pathname.startsWith("/corperatorsDetails")
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/corperators" className="menu-link ">
                  <div data-i18n="Dashboard">
                    <FontAwesomeIcon
                      icon={faFileCirclePlus}
                      size="xs"
                      className="me-2"
                    />
                    Corporators
                  </div>
                </Link>
              </li>

              <li
                className={`menu-item active ${dashboardMenu} ${
                  pathname === "/Partnerwithus" ||
                  pathname === "/PrivacyPolicy" ||
                  pathname === "/termsConditions" ||
                  pathname === "/aboutUs" ||
                  pathname === "/permissions"
                    ? "open"
                    : ""
                }`}
                onClick={handleDashboardMenu}
              >
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                  <div data-i18n="Dashboard">
                    {" "}
                    <FontAwesomeIcon
                      icon={faUserGear}
                      size="xs"
                      className="me-2"
                    />
                    Settings
                  </div>
                </a>
                <ul className="menu-sub" style={{ listStyle: "none" }}>
                  <li
                    className={`menu-item ${
                      pathname === "/Partnerwithus" && "active"
                    }`}
                  >
                    <Link to="/Partnerwithus" className="menu-link">
                      <FontAwesomeIcon
                        icon={faUser}
                        size="xs"
                        className="me-2"
                      />{" "}
                      Partner with us
                    </Link>
                  </li>
                  <li
                    className={`menu-item ${
                      pathname === "/PrivacyPolicy" && "active"
                    }`}
                  >
                    <Link to="/PrivacyPolicy" className="menu-link">
                      <FontAwesomeIcon
                        icon={faUserSecret}
                        size="xs"
                        className="me-2"
                      />{" "}
                      Privacy policy
                    </Link>
                  </li>
                  <li
                    className={`menu-item ${
                      pathname === "/termsConditions" && "active"
                    }`}
                  >
                    <Link to="/termsConditions" className="menu-link">
                      <FontAwesomeIcon
                        icon={faFilter}
                        size="xs"
                        className="me-2"
                      />{" "}
                      Terms and conditions
                    </Link>
                  </li>
                  <li
                    className={`menu-item ${
                      pathname === "/aboutUs" && "active"
                    }`}
                  >
                    <Link to="/aboutUs" className="menu-link">
                      <FontAwesomeIcon
                        icon={faTag}
                        size="xs"
                        className="me-2"
                      />{" "}
                      About us
                    </Link>
                  </li>
                  <li
                    className={`menu-item ${
                      pathname === "/permissions" && "active"
                    }`}
                  >
                    <Link to="/permissions" className="menu-link">
                      <FontAwesomeIcon
                        icon={faShopLock}
                        size="xs"
                        className="me-2"
                      />{" "}
                      Permissions
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`menu-item active ${accountMenu}`}
                onClick={handleAccountMenu}
              >
                <a href="javascript:void(0);" className="menu-link menu-toggle">
                  <div data-i18n="Account Settings">
                    <FontAwesomeIcon
                      icon={faUserShield}
                      size="xs"
                      className="me-2"
                    />{" "}
                    Security
                  </div>
                </a>
                <ul className="menu-sub">
                  <li className={`menu-item ${pathname === "#" && "active"}`}>
                    <Link to="#" className="menu-link">
                      <div data-i18n="Account">
                        <FontAwesomeIcon
                          icon={faPenNib}
                          size="xs"
                          className="me-2"
                        />{" "}
                        Reset password
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className={`menu-item ${
                  pathname === "/partners" ||
                  pathname.startsWith("/partnersDetails") ||
                  pathname === "/addPartner" ||
                  pathname.startsWith("/editPartner")
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/partners" className="menu-link ">
                  <div data-i18n="Dashboard">
                    {" "}
                    <FontAwesomeIcon
                      icon={faParachuteBox}
                      size="xs"
                      className="me-2"
                    />
                    Partners
                  </div>
                </Link>
              </li>
              <li className={`menu-item `}>
                <Link to="/dashboard" className="menu-link ">
                  <div data-i18n="Dashboard">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      size="xs"
                      className="me-2"
                    />{" "}
                    Manage Payments
                  </div>
                </Link>
              </li>

              <li className={`menu-item `}>
                <Link to="/dashboard" className="menu-link ">
                  <div data-i18n="Dashboard">
                    <FontAwesomeIcon
                      icon={faHandshake}
                      size="xs"
                      className="me-2"
                    />
                    Help
                  </div>
                </Link>
              </li>
              <li className={`menu-item `}>
                <Link to="/dashboard" className="menu-link ">
                  <div data-i18n="Dashboard">
                    <FontAwesomeIcon icon={faBell} size="xs" className="me-2" />{" "}
                    Notifications
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="landing-menu-overlay d-lg-none" />
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user ">
              <Link
                className="nav-link dropdown-toggle hide-arrow"
                to="javascript:void(0);"
                data-bs-toggle="dropdown"
              >
                <div className="avatar avatar-online">
                  <img
                    src={"../../assets/img/avatars/14.jpg"}
                    alt
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-end header-menu"
                style={{ position: "absolute !important" }}
              >
                <li>
                  <Link className="dropdown-item">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src={"../../assets/img/avatars/14.jpg"}
                            alt
                            // className="h-auto rounded-circle"
                            style={{
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-medium d-block">
                          {userData?.first_name}
                        </span>
                        <small className="text-muted">Admin</small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="/profile">
                    <i className="ti ti-user-check me-2 ti-sm"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    <i className="ti ti-settings me-2 ti-sm"></i>
                    <span className="align-middle">Settings</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    <span className="d-flex align-items-center align-middle">
                      <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                      <span className="flex-grow-1 align-middle">Billing</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="/faqsid">
                    <i className="ti ti-help me-2 ti-sm"></i>
                    <span className="align-middle">FAQ</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    <i className="ti ti-currency-dollar me-2 ti-sm"></i>
                    <span className="align-middle">Pricing</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={handleLogout}>
                    <i className="ti ti-currency-dollar me-2 ti-sm"></i>
                    <span className="align-middle">Logout</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
