import { Container } from "components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./conformation.css";
import api from "services/api";
import { KYC } from "services/api.config";
import { useToaster } from "hooks";

export const Conformation = () => {
  const [showKyc, setShowKyc] = useState([]);
  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const location = useLocation();
  const { data } = location.state || {};
  const params = useParams();
  const { setSuccessToaster, setErrorToaster } = useToaster();
  const navigate = useNavigate();

  const naviKycData = () => {
    try {
      setShowKyc(data);
      console.log("dataf:", data);
    } catch (error) {
      console.log("naviKycData:", error);
    }
  };

  useEffect(() => {
    naviKycData();
  }, []);

  const handleAccept = async () => {
    const payloadAccept = {
      ...data,
      status: "Approved",
      notes: "Approved by admin",
    };

    try {
      const response = await api.post(`${KYC}/${data?.id}`, payloadAccept);
      console.log("handleAccept", response);
      setSuccessToaster("KYC status Approved");
      navigate('/membershipDetails/:id')
    } catch (error) {
      let details = error?.response?.data?.message;
      console.log("handleAccept error:", details);
      setErrorToaster(details);
    }
  };

  const handleReject = () => {
    setIsRejecting(true);
  };

  const handleSubmitRejection = async () => {
    const payloadReject = {
      ...data,
      status: "Rejected",
      notes: rejectionReason,
    };

    try {
      const response = await api.post(`${KYC}/${data?.id}`, payloadReject);
      console.log("handleReject", response);
      setSuccessToaster("KYC status Rejected");
      navigate('/membershipDetails/:id')

      // navigate("/membershipDetails/:id")
    } catch (error) {
      let details = error?.response?.data?.message;
      console.log("handleReject error:", details);
      setErrorToaster(details);
    }
  };

  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0"></div>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className="d-flex justify-content-around align-items-center">
                    <h5 className="card-header pb-3">Aadhaar card</h5>
                    <h5 className="card-header pb-3 me-5">Pan card</h5>
                  </div>
                  <div className="document-container my-3">
                    {showKyc?.aadhar_document_url && (
                      <img
                        src={showKyc.aadhar_document_url}
                        alt="Aadhaar Document"
                        className="document-image"
                      />
                    )}
                    {showKyc?.pan_document_url && (
                      <img
                        src={showKyc.pan_document_url}
                        alt="PAN Document"
                        className="document-image"
                      />
                    )}
                  </div>
                  {showKyc?.status === "Pending" && (
                    <div className="d-flex justify-content-center align-items-center my-3">
                      <button
                        className="btn btn-primary mx-2"
                        onClick={handleAccept}
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={handleReject}
                      >
                        Reject
                      </button>
                    </div>
                  )}

                  {isRejecting && (
                    <div className="d-flex flex-column justify-content-center align-items-center my-3">
                      <textarea
                        className="form-control my-1"
                        placeholder="Enter rejection reason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        style={{ width: "60%" }}
                      />
                      <button
                        className="btn btn-danger mx-2"
                        onClick={handleSubmitRejection}
                      >
                        Submit Rejection
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
