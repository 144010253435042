import React, { useEffect, useState } from "react";
import api from "services/api";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import { Container } from "components";
import { MEMBERSHIPALL } from "services/api.config";

const MembershipSelection = (props) => {
  const [activeMembership, setActiveMembership] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    fetchMembership();
  }, [params?.id]);

  const fetchMembership = () => {
    api.get(`/${MEMBERSHIPALL}/${params?.id}`).then((res) => {
      if (res?.data?.data?.membershipPlans) {
        console.log("get plan details:",res?.data?.data?.membershipPlans)
        setActiveMembership(res?.data?.data?.membershipPlans);
      }
    });
  };

  const getImage = (type) => {
    switch (type) {
      case "Individual":
        return "../../assets/img/illustrations/page-pricing-basic.png";
      case "Family":
        return "../../assets/img/illustrations/page-pricing-standard.png";
      case "Corporate":
        return "../../assets/img/illustrations/page-pricing-enterprise.png";
      default:
        return "../../assets/img/illustrations/page-pricing-basic.png";
    }
  };

  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/showplansall">Plans</Link>
              </li>
              <li class="breadcrumb-item active ">Plan details</li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-6 col-12">
              <div class="card border rounded shadow-none">
                <div class="card-body">
                  <div class="my-3 pt-2 text-center">
                    <img
                      src={getImage(activeMembership?.membership_type)}
                      alt="Enterprise Image"
                      height="140"
                    />
                  </div>
                  <h3 class="card-title text-center text-capitalize mb-1"
                    dangerouslySetInnerHTML={{__html:activeMembership?.title}}
                  />
                  <p class="text-center"
                  dangerouslySetInnerHTML={{__html:activeMembership?.short_text}}/>

                  <div class="text-center">
                    <div class="d-flex justify-content-center">
                      <sup class="h6 text-primary pricing-currency mt-3 mb-0 me-1">
                      ₹
                      </sup>
                      <h1 class="price-toggle price-yearly display-4 text-primary mb-0">
                        {activeMembership?.package_price}
                      </h1>
                      <h1 class="price-toggle price-monthly display-4 text-primary mb-0 d-none">
                        {activeMembership?.package_price}
                      </h1>
                      <sub class="h6 pricing-duration mt-auto mb-2 fw-normal text-muted">
                        /{activeMembership?.duration} {activeMembership?.duration_type}
                      </sub>
                    </div>
                  </div>

                  <ul class="ps-3 my-4 pt-2">
                    <li class="mb-2">
                      {" "}
                      Upto {activeMembership?.no_of_users}{" "}
                      {activeMembership?.membership_type === "Individual"
                        ? "memeber"
                        : "members"}
                    </li>
                    <li class="mb-2">
                      {activeMembership?.duration}{" "}
                      {activeMembership?.duration_type?.toLowerCase()}{" "}
                      membership
                    </li>
                    <li class="mb-2">
                      {" "}
                      Your Current Plan is {activeMembership?.membership_type}
                    </li>
                  </ul>

                  {/* <button
                    className="btn btn-label-primary d-grid w-100"
                    onClick={() => navigate(`/membershipForm/${params?.id}`)}
                  >
                    Apply
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div class="card mb-4" style={{ height: "100%" }}>
                <div class="card-body">
                  <div class="col-md-12 mb-1">
                    <div class="mb-3">
                      <h6 class="mb-1">Description</h6>
                      <p
                      dangerouslySetInnerHTML={{__html:activeMembership?.description}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4 mt-4">
            <h5 class="card-header">Membership refund policy</h5>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-1">
                  <div class="mb-3">
                    <p
                    dangerouslySetInnerHTML={{__html:activeMembership?.return_policy}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-backdrop fade"></div>
        </div>
      </div>
    </Container>
  );
};

export default MembershipSelection;
