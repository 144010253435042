import React, { useEffect, useState } from "react";

import { Container } from "components";
import Footer from "components/footer";
import { AgCharts } from "ag-charts-react";
import api from "services/api";

export const Home = () => {
  // const chartOptions = {
  //   data: [
  //     { month: "Jan", iceCreamSales: 162000 },
  //     { month: "Feb", iceCreamSales: 162000 },
  //     { month: "Mar", iceCreamSales: 302000 },
  //     { month: "April", iceCreamSales: 800000 },
  //     { month: "May", iceCreamSales: 1254000 },
  //     { month: "June", iceCreamSales: 950000 },
  //     { month: "July", iceCreamSales: 200000 },
  //     { month: "Aug", iceCreamSales: 302000 },
  //     { month: "Sep", iceCreamSales: 800000 },
  //     { month: "Oct", iceCreamSales: 1254000 },
  //     { month: "Nov", iceCreamSales: 950000 },
  //     { month: "Dec", iceCreamSales: 200000 },
  //   ],
  //   series: [
  //     {
  //       type: "bar",
  //       xKey: "month",
  //       yKey: "iceCreamSales",
  //       fillOpacity: 0.8,
  //       strokeOpacity: 0.9,
  //       radius: 30, // Adds a top radius
  //       paddingInner: 0.3, // Reduces bar width
  //       paddingOuter: 0.2,
  //     },
  //   ],
  // };
  const [getDash ,setGetDash] = useState({});
  const [chartOptions , setChartOptions] = useState({});
  const [salesChartOptions, setSalesChartOptions] = useState({})
  const [earningReport , SetEarningReport] = useState({})
  
  const getDashBoardAdmin = async () => {
    try {
      const response = await api.get('/admin-dashboard');
      const list = response?.data?.data[0];
      setGetDash(list)
      console.log("getDashBoardAdmin:",list);

      const chartData = list?.no_of_user_registered_per_month?.map(item => ({
        month: item.month_year,
        registrations: item.total_users,
      }));
      const salesData = list?.no_of_sales_per_month?.map( (item) => ({
        month: item?.month_name,
        sales:  item.transaction_count ,
      }))
      const earningData = list?.earning_report?.map( (item) => ({
        month: item?.month_name,
        sales:   Number(item?.total_amount) || 0,
      }))
       // Update chart options
       setChartOptions({
        data: chartData || [],
        series: [
          {
            type: 'bar',
            xKey: 'month',
            yKey: 'registrations',
            fillOpacity: 0.8,
            strokeOpacity: 0.9,
            radius: 30, // Adds a top radius
            paddingInner: 0.3, // Reduces bar width
            paddingOuter: 0.2,
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
          },
          {
            type: 'number',
            position: 'left',
          },
        ],
        legend: {
          enabled: false, // Disable legend if not needed
        },
      });
      // sales
      setSalesChartOptions({
        data: salesData || [],
        series: [
          {
            type: 'bar',
            xKey: 'month',
            yKey: 'sales',
            fillOpacity: 0.8,
            strokeOpacity: 0.9,
            radius: 30,
            paddingInner: 0.3,
            paddingOuter: 0.2,
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
          },
          {
            type: 'number',
            position: 'left',
          },
        ],
        legend: {
          enabled: false,
        },
      });
      // earning report
      SetEarningReport({
        data: earningData || [],
        series: [
          {
            type: 'bar',
            xKey: 'month',
            yKey: 'sales',
            fillOpacity: 0.8,
            strokeOpacity: 0.9,
            radius: 30,
            paddingInner: 0.3,
            paddingOuter: 0.2,
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
          },
          {
            type: 'number',
            position: 'left',
          },
        ],
        legend: {
          enabled: false,
        },
      });

    } catch (error) {
      console.log("getDashBoardAdmin error:",error);
      
    }
  }

  useEffect( () => {
    getDashBoardAdmin();
  },[])
  return (
    <Container>
      <div class="content-wrapper">
        <div class="container-xxl flex-grow-1 container-p-y">
          <h4 className="card-header">Dashboard</h4>
          <div class="row">
            <div class="col-sm-6 col-lg-3 mb-4">
              <div class="card card-border-shadow-primary">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                      <span class="avatar-initial rounded bg-label-primary">
                        <i class="ti ti-truck ti-md"></i>
                      </span>
                    </div>
                    <h4 class="ms-1 mb-0">{getDash?.no_of_users}</h4>
                  </div>
                  <p class="mb-1">Number of users</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 mb-4">
              <div class="card card-border-shadow-warning">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                      <span class="avatar-initial rounded bg-label-warning">
                        <i class="ti ti-alert-triangle ti-md"></i>
                      </span>
                    </div>
                    <h4 class="ms-1 mb-0">{getDash?.no_of_active_users}</h4>
                  </div>
                  <p class="mb-1">Number of active users</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 mb-4">
              <div class="card card-border-shadow-danger">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                      <span class="avatar-initial rounded bg-label-danger">
                        <i class="ti ti-git-fork ti-md"></i>
                      </span>
                    </div>
                    <h4 class="ms-1 mb-0">{getDash?.no_of_inactive_users}</h4>
                  </div>
                  <p class="mb-1">Number of inactive users</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 mb-4">
              <div class="card card-border-shadow-info">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                      <span class="avatar-initial rounded bg-label-info">
                        <i class="ti ti-clock ti-md"></i>
                      </span>
                    </div>
                    <h4 class="ms-1 mb-0">{getDash?.total_sales}</h4>
                  </div>
                  <p class="mb-1">Total sales</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-title mb-0">
                  <h5 class="mb-0">No of Registrations</h5>
                  <small class="text-muted">
                    Last 12 Months Users Registrations Overview
                  </small>
                </div>
              </div>
              <div class="card-body">
                <div class="tab-content p-0 ms-0 ms-sm-2">
                  <div
                    class="tab-pane fade show active"
                    id="navs-orders-id"
                    role="tabpanel"
                  >
                    {chartOptions.data && (
                      <AgCharts options={chartOptions} />
                    )}
                    {/* <AgCharts options={chartOptions} /> */}
                    <div id="earningReportsTabsOrders"></div>
                  </div>
                  <div class="tab-pane fade" id="navs-sales-id" role="tabpanel">
                    <div id="earningReportsTabsSales"></div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="navs-profit-id"
                    role="tabpanel"
                  >
                    <div id="earningReportsTabsProfit"></div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="navs-income-id"
                    role="tabpanel"
                  >
                    <div id="earningReportsTabsIncome"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-title mb-0">
                  <h5 class="mb-0">No of Sales</h5>
                  <small class="text-muted">
                  Last 12 Months No of Sales Overview
                  </small>
                </div>
              </div>
              <div class="card-body">
                <div class="tab-content p-0 ms-0 ms-sm-2">
                  <div
                    class="tab-pane fade show active"
                    id="navs-orders-id"
                    role="tabpanel"
                  >
                    {salesChartOptions.data && (
                      <AgCharts options={salesChartOptions}/>
                    )}
                    {/* <AgCharts options={chartOptions} /> */}
                    <div id="earningReportsTabsOrders"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-title mb-0">
                  <h5 class="mb-0">Earning Report</h5>
                  <small class="text-muted">
                  Last 12 Months No of Earning Overview
                  </small>
                </div>
              </div>
              <div class="card-body">
                <div class="tab-content p-0 ms-0 ms-sm-2">
                  <div
                    class="tab-pane fade show active"
                    id="navs-orders-id"
                    role="tabpanel"
                  >
                    {earningReport.data && (
                      <AgCharts options={earningReport} />
                    )}
                    {/* <AgCharts options={chartOptions} /> */}
                    <div id="earningReportsTabsOrders"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-12 mb-4">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="card-title mb-0">
                  <h5 class="mb-0">Pending Membership approvals</h5>
                  <small class="text-muted">
                    Current Year Pending Membership 
                  </small>
                </div>
              </div>
              <div class="card-body">
                <div class="tab-content p-0 ms-0 ms-sm-2">
                  <div className="card-datatable table-responsive">
                    <table className="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th className="text-truncate">Membership Type</th>
                          <th className="text-truncate">Duration</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Silver Plan</td>
                          <td>Family</td>
                          <td>UpTo 30 Days</td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};
