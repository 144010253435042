import { Container } from "components";
import CustomDatepicker from "components/CustomDatePicker";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import * as Yup from "yup";

const initialValues = {
  name: "",
  type: "",
  address: "",
  contract_start_date: "",
  contract_end_date: "",
  contact_info: "",
  inclusions: "",
  exclusions: "",
  price: "",
  email:"",
  // discount_price: "",
  latitude: "",
  longitude: "",
};
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  contract_start_date: Yup.date()
  .transform((value, originalValue) =>
    moment(originalValue, "DD-MM-YYYY", true).isValid()
      ? moment(originalValue, "DD-MM-YYYY").toDate()
      : null
  )
  .required("Required"),
  contract_end_date: Yup.date()
  .transform((value, originalValue) =>
    moment(originalValue, "DD-MM-YYYY", true).isValid()
      ? moment(originalValue, "DD-MM-YYYY").toDate()
      : null
  )
  .required("Required")
  .min(
    Yup.ref("contract_start_date"),
    "Contract end date must be after the start date"
  ),
  contact_info: Yup.string().required("Required"),
  //   inclusions: Yup.string(),
  exclusions: Yup.string(),
  email: Yup.string()
  .email("Invalid email format")
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 
    "Invalid email format"
  )
  .required("Required"),
  price: Yup.string().required("Required"),
  // discount_price: Yup.string().required("Required"),
  latitude: Yup.string().required("Required"),
  longitude: Yup.string().required("Required"),
});
export const EditPartner = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [deta, setDeta] = useState({ ...initialValues });
  const {setSuccessToaster,setErrorToaster} = useToaster();
// here edit function start
  const handlePartnerDetails = (details) => {
    const {
      address,
      contact_info,
      contract_end_date,
      contract_start_date,
      created_at,
      discount_price,
      email,
      contact_person,
      exclusions,
      inclusions,
      latitude,
      longitude,
      name,
      price,
      type,
    } = details;
    const updateData = {
      address: address,
      contact_info: contact_info,
      contract_end_date: moment(contract_end_date, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      contract_start_date: moment(contract_start_date, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      created_at: created_at,
      discount_price: discount_price,
      email: email,
      exclusions: exclusions,
      inclusions: inclusions,
      latitude: latitude,
      contact_person:contact_person,  
      longitude: longitude,
      name: name,
      price: price,
      type: type,
    };
    setDeta(updateData);
  };
  const fetchPartnerDetails = async () => {
    try {
      const response = await api.get(`/partners/${params.id}`);
      const details = response?.data?.data?.partner;
      if (details) {
        handlePartnerDetails(details);
        console.log("details", details);
      }
    } catch (error) {
      console.log("fetchPartnerDetails error:");
    }
  };
  useEffect(() => {
    if (params.id) {
      fetchPartnerDetails();
    }
  }, []);
// here edit function end
  const handleSubmit = async (values) => {
    const payLoad = {
      ...values,
    };
    if (params?.id) {//throgh params if(edit)
      try {
        const response = await api.post(`/partners/${params?.id}`, payLoad);
        if(response?.status === 201) {
          setSuccessToaster(response?.data?.message)
          navigate("/partners")
          console.log("handleSubmit", response);

        }

      } catch (error) {
        console.log("handleSubmitEdit Error:", error);
        setErrorToaster(error?.response?.data?.message)
      }
    } else {//if not params (add partner)
      try {
        const response = await api.post("/partners", payLoad);
        console.log("handleSubmit", response);
        if(response?.status ===201) {
          setSuccessToaster(response?.data?.message);
          navigate("/partners")
        }
        // setSuccessToaster()
      } catch (error) {
        console.log("handleSubmit add Error:", error);
        setErrorToaster(error?.response?.data?.message  )
      }
    }
  };
  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/partners">Partners</Link>
              </li>
              <li class="breadcrumb-item active ">{params?.id ? "Edit" :"Add"} partner</li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              <Formik
                initialValues={deta}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                  console.log("values", values);
                }}
                enableReinitialize
              >
                {({ values, errors, setFieldValue }) => {
                  return (
                    
                    <Form>
                      {console.log("checking",values.contract_start_date)}
                      {console.log("checking end",values.contract_end_date)}
                      {console.log("errors:",errors)}
                      {console.log("values", values)}
                      <div className="card mb-4">
                        <h5 className="card-header">{params?.id ? "Edit" :"Add"} partner</h5>
                        <hr className="my-0" />
                        <div className="card-body">
                          <div className="row mb-3">
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                Name <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                onChange={(e) => {
                                  if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                    setFieldValue("name", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                Email <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="email"
                                className="form-control"
                                placeholder="Enter your Email"
                                name="email"
                                onChange={(e) => {
                                  const email = e.target.value;
                                  const isValidEmail = /^[A-Za-z0-9@.]/.test(email);
                                  if(isValidEmail){
                                    setFieldValue("email",email)
                                  }
                                }}
                                
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                Type <span className="invalid-form">*</span>
                                </label>
                              <Field
                                as="select"
                                className="select2 form-select"
                                name="type"
                              >
                                <option value="">Select</option>
                                <option value="Hospital">Hospital</option>
                                <option value="Lab">Lab</option>
                                <option value="Pharmacy">Pharmacy</option>
                              </Field>
                              <ErrorMessage
                                name="type"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                Contact number <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="tel"
                                className="form-control"
                                placeholder="Contact "
                                name="contact_info"
                                maxLength="10"
                                onChange={(e) => {
                                  if (/^[0-9]*$/.test(e.target.value)) {
                                    setFieldValue(
                                      "contact_info",
                                      e.target.value
                                    );
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="contact_info"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                                <label className="form-label">
                                  Contact person <span className="invalid-form">*</span>
                                </label>
                                <Field
                                type="text"
                                className="form-control"
                                placeholder="Contact person"
                                name="contact_person"
                                onChange={(e) => {
                                  if (/^[A-Za-z ]*$/.test(e.target.value)) {
                                    setFieldValue("contact_person", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="contact_person"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="form-label">
                                Price <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="tel"
                                className="form-control"
                                name="price"
                                placeholder="Price"
                                onChange={(e) => {
                                  if (/^[0-9]*$/.test(e.target.value)) {
                                    setFieldValue("price", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Contract start date <span className="invalid-form">*</span>
                              </label>
                              <Field
                                className="form-control"
                                name="contract_start_date"
                                value={values.contract_start_date}
                                component={CustomDatepicker}
                                // min={null}
                              />
                              <ErrorMessage
                                name="contract_start_date"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Contract end date <span className="invalid-form">*</span>
                              </label>
                              <Field
                                className="form-control"
                                name="contract_end_date"
                                value={values.contract_end_date}
                                component={CustomDatepicker}
                                min={values.contract_start_date ? moment(values.contract_start_date, "DD-MM-YYYY").toDate():null}
                              />
                              <ErrorMessage
                                name="contract_end_date"
                                component="div"
                                className="invalid-form"
                              />
                              {/* {values.contract_end_date < values.contract_start_date && (
                                <p>hi</p>
                              )} */}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label className="form-label">Exclusions</label>
                              {/* <Field
                                type="text"
                                className="form-control"
                                name="exclusions"
                                placeholder="exclusions"
                              /> */}
                              {/* <ErrorMessage
                              name="exclusions"
                              component="div"
                              className="invalid-form"
                            /> */}
                            <JoditEditor
                              value={values.exclusions}
                              onChange={(newContent) =>
                                setFieldValue("exclusions", newContent)
                              }
                            />
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="form-label">Inclusions</label>
                              {/* <Field
                                type="text"
                                className="form-control"
                                placeholder="Inclusions"
                                name="inclusions"
                              /> */}
                              <JoditEditor
                              value={values.inclusions}
                              onChange={(newContent) =>
                                setFieldValue("inclusions", newContent)
                              }
                            />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Latitude <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="tel"
                                className="form-control"
                                name="latitude"
                                placeholder="Latitude"
                                onChange={(e) => {
                                  if (/^[0-9.]*$/.test(e.target.value)) {
                                    setFieldValue("latitude", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="latitude"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="form-label">
                                Longitude <span className="invalid-form">*</span>
                                </label>
                              <Field
                                type="tel"
                                className="form-control"
                                name="longitude"
                                placeholder="Longitude"
                                onChange={(e) => {
                                  if (/^[0-9.]*$/.test(e.target.value)) {
                                    setFieldValue("longitude", e.target.value);
                                  }
                                }}
                              />
                              <ErrorMessage
                                name="longitude"
                                component="div"
                                className="invalid-form"
                              />
                            </div>
                            
                          </div>
                          <div className="row mb-3">
                            <label className="form-label">Address</label>
                            <Field
                              as="textarea"
                              className="form-control"
                              rows="3"
                              placeholder="Address"
                              name="address"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <button
                            className="btn btn-primary d-grid w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
