import React, { useState, useEffect } from "react";
import { Container } from "components";
import api from "services/api";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Pagination from "components/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil } from "@fortawesome/free-solid-svg-icons";


const MemebershipsList = () => {
  const [showMembership, setShowMembership] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const navigate = useNavigate();
  const [currentPage , SetCurrentPage ] = useState(1);
  const [search , setSearch ] = useState("");

  const fetchUsersList = async (page=1,query="") => {
    try {
      const response = await api.get(`/users?role=user&page=${page}&search=${query}`);
      setMemberships(response?.data?.data);
      SetCurrentPage(page)
      console.log("fetchUsersList:", response?.data?.data);
    } catch (error) {
      console.log("fetchUsersList error:", error);
    }
  };
  // ?search=${search.title}
  useEffect(() => {
    fetchUsersList(1,search);
  }, []);
  const handleSearch = () => {
    const query = search.trim(); // Trim whitespace from the input
  fetchUsersList(1, query); // Pass an empty string if the input is empty
  };


  console.log("memberships",memberships?.pagination?.total)

  return (
    <Container>
      <div class="content-wrapper">
        <div
          class="container-xxl flex-grow-1 container-p-y"
          style={{ height: "43px" }}
        >
          <h4 className="card-header">Users</h4>
          <div className="d-flex justify-content-end">
            <input 
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)} // Update the search state.
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSearch(); // Trigger search on Enter
              }}
              placeholder="search"
              className=" me-3 mb-4 "
              style={{ outline: 'none', boxShadow: 'none', border: "1px solid transparent"}}
            />
            <button onClick={handleSearch} className="btn btn-primary me-2 mb-4">
              Search
            </button>
            <button className="btn btn-primary me-5 mb-4" onClick={() => navigate("/addUser")}>
              Add user
            </button>
          </div>
          <div class="card-datatable table-responsive">
            <table class="invoice-list-table table border-top dataTable no-footer dtr-column mb-5">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>email</th>

                  <th class="text-truncate">Registred ON</th>
                  <th class="text-truncate">KYC </th>
                  <th class="cell-fit">Phone No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {memberships?.users?.length > 0 ? (
                memberships?.users?.map((res, index) => {
                   const serialNumber = (currentPage - 1) * memberships?.pagination?.per_page + (index + 1);
                  
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>
                        <img
                          src={res.profile.profile_pic_url}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                      <td>
                        {res.first_name} {res.middle_name} {res.last_name}
                      </td>
                      <td>{res.email}</td>
                      <td>
                        {moment(res.profile.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            res.profile.kyc_status === "Pending"
                              ? "bg-warning"
                              : res.profile.kyc_status === "Approved"
                              ? "bg-success"
                              : "bg-danger"
                          }`}
                        >
                          {res.profile.kyc_status}
                        </span>
                      </td>
                      <td>{res.profile.phone_no}</td>
                      <td >
                        {/* <button
                          variant="outlined"
                          className="btn btn-primary"
                          onClick={() =>
                            navigate(`/membershipDetails/${res.user_id}`)
                          }
                        >
                          View
                        </button> */}
                    
                            <FontAwesomeIcon  icon={faEye} onClick={() => navigate(`/membershipDetails/${res.user_id}`)} className="me-3"/>
                            <FontAwesomeIcon title="Edit" icon={faPencil} onClick={() => navigate(`/userDetailsEdit/${res.user_id}`)}/>
                      </td>
                    </tr>
                  );
                }) ) : (
                  <tr>
                  <td colSpan="8" className="text-center">
                    <strong>Data not found</strong>
                  </td>
                </tr>
                )
                }
              </tbody>
            </table>
          {memberships?.pagination?.total > 0 && <Pagination totalPages={(memberships?.pagination?.total)} perPage={memberships?.pagination?.per_page} handleFunction={fetchUsersList}/>}
          </div>
        </div>
        {/* <Footer /> */}
        <div class="content-backdrop fade"></div>
      </div>
    </Container>
  );
};

export default MemebershipsList;
