import { Container } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "services/api";

export const PartnerDetails = () => {
  const params = useParams();
  const [partner, setPartner] = useState([]);

  const fetchParticularData = async () => {
    try {
      const response = await api.get(`/partners/${params.id}`);
      setPartner(response?.data?.data?.partner);
      console.log("fetchParticularData:", response?.data?.data?.partner);
    } catch (error) {
      console.log("fetchParticularData error:", error);
    }
  };
  useEffect(() => {
    fetchParticularData();
  }, []);
  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0"></div>
          <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <Link to="/partners">Partners</Link>
                </li>
                <li class="breadcrumb-item active ">Paartner details</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <small className="card-text text-uppercase">
                          About
                        </small>
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Name
                            </span>
                            <span>{partner?.name}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-list-details text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Type
                            </span>
                            <span>{partner?.type}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-box-model text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Price
                            </span>
                            <span>{partner?.price}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-phone-call text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Contact
                            </span>
                            <span>{partner?.contact_info}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-user text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Contact person
                            </span>
                            <span>{partner?.contact_person}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-calendar text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Contract start date
                            </span>
                            <span>{moment(partner?.contract_start_date).format("DD-MM-YYYY")}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-calendar text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Contract end date
                            </span>
                            <span>{moment(partner?.contract_start_date).format("DD-MM-YYYY")}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-8 col-12">
                        <ul className="list-unstyled mb-4 mt-3">
                          <li className="d-flex align-items-top mb-3">
                            <i className="ti ti-files text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Exclusions
                            </span>
                            <span 
                            dangerouslySetInnerHTML={{__html:partner?.exclusions}}/>
                          </li>
                          <li className="d-flex align-items-top mb-3">
                            <i className="ti ti-id text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Inclusions
                            </span>
                            <span
                            dangerouslySetInnerHTML={{__html:partner?.inclusions}}/>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-map-pin text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Longitude
                            </span>
                            <span>{partner?.longitude}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-map-pin text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Latitude
                            </span>
                            <span>{partner?.latitude}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-calendar text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                              Created at
                            </span>
                            <span>{moment(partner?.created_at).format("DD-MM-YYYY")}</span>
                          </li>
                          <li className="d-flex align-items-center mb-3">
                            <i className="ti ti-bell text-heading"></i>
                            <span className="fw-medium mx-2 text-heading">
                                Address
                            </span>
                            <span>{partner?.address}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
