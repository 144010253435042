import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "services/api";
import Logo from "assets/images/logo.jpg";
import { useToaster } from "hooks";
import { BASE_URL } from "../../services/api.config"
import  Axios  from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye , faEyeSlash} from "@fortawesome/free-solid-svg-icons";

export const Login = () => {
  const { setErrorToaster } = useToaster();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showPassword , setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      password: Yup.string()
      .required('Password is required')

  });

  const handleSubmit = (values) => {
    
    setLoader(true);
    Axios.post(`${BASE_URL}/admin-login`, { ...values })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data));
        navigate("/dashboard");
        setLoader(false);
        console.log("Admin Login:",res.data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setErrorToaster("Please Enter The Valid Credentials");
          setLoader(false);
          console.log("err:",err)
        }
      });
  };

  return (
    <div className="authentication-wrapper authentication-cover authentication-bg">
      <div className="authentication-inner row">
        <div className="d-none d-lg-flex col-lg-7 p-0">
          <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
            <img
              src="assets/img/illustrations/auth-login-illustration-light.png"
              alt="auth-login-cover"
              className="img-fluid my-5 auth-illustration"
              data-app-light-img="illustrations/auth-login-illustration-light.png"
              data-app-dark-img="illustrations/auth-login-illustration-dark.png"
            />

            <img
              src="assets/img/illustrations/bg-shape-image-light.png"
              alt="auth-login-cover"
              className="platform-bg"
              data-app-light-img="illustrations/bg-shape-image-light.png"
              data-app-dark-img="illustrations/bg-shape-image-dark.png"
            />
          </div>
        </div>
        <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
          <div className="w-px-400 mx-auto">
            <div className="app-brand mb-2">
              <a href="index.html" className="app-brand-link ">
                <span className="app-brand-logo demo">
                  <img src="../assets/img/flowersvg.svg" 
                    style={{
                      width: "40px",
                      height: "26px",
                      // borderRadius: "50%",
                    }}
                  />
                </span>
              </a>
            </div>
            <h3 className="mb-1">Welcome to Zenosure! 👋</h3>
            <p className="mb-4">
              Please sign-in to your account and start the adventure
            </p>

            
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div className="mb-3">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <Field
                      type="email"
                      className={`form-control ${
                        touched.email && errors.email
                          ? "invalid-form-input"
                          : ""
                      }`}
                      name="email"
                      placeholder="Enter your email or username"
                      autofocus
                    />

                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-form"
                    />
                  </div>

                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" for="password">
                        Password
                      </label>
                      <Link to="/restPassword">
                        <small>Forgot Password?</small>
                      </Link>
                    </div>
                    <div className="input-group input-group-merge ">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className={`form-control ${
                          touched.email && errors.email
                            ? "invalid-form-input"
                            : ""
                        }`}
                        placeholder="••••••••••••"
                        aria-describedby="password"
                      />
                      <span className="input-group-text"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{cursor: "pointer"}}
                      >
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </span>
                    </div>

                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-form"
                    />
                  </div>
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                      />
                      <label className="form-check-label" for="remember-me">
                        
                        Remember Me
                      </label>
                    </div>
                  </div>
                  {loader ? (
                    <div className="text-center">
                      <div class="spinner-border text-primary " role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button className="btn btn-primary d-grid w-100" type="submit">
                      Sign in
                    </button>
                  )}
                </Form>
              )}
            </Formik>

            {/* <p className="text-center mt-3">
              <span className="me-2">New on our platform?</span>
              <Link to="/register">
                <span>Create an account</span>
              </Link>
            </p>

            <div className="divider my-4">
              <div className="divider-text">or</div>
            </div>

            <div className="d-flex justify-content-center">
              <a
                href="javascript:;"
                className="btn btn-icon btn-label-facebook me-3"
              >
                <i className="tf-icons fa-brands fa-facebook-f fs-5"></i>
              </a>

              <a
                href="javascript:;"
                className="btn btn-icon btn-label-google-plus me-3"
              >
                <i className="tf-icons fa-brands fa-google fs-5"></i>
              </a>

              <a href="javascript:;" className="btn btn-icon btn-label-twitter">
                <i className="tf-icons fa-brands fa-twitter fs-5"></i>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
