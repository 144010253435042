import React, { useEffect, useState,useRef } from "react";
import { Container } from "components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useToaster } from "hooks";
import JoditEditor from "jodit-react";

import { Link, useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import { MEMBERSHIPALL } from "services/api.config";
import * as Yup from "yup";

const initialValues = {
  title: "",
  short_text: "",
  no_of_users: "",
  package_price: "",
  duration: "",
  description: "",
  return_policy: "",
  membership_type: "",
  duration_type: "",
};
const stripHtmlTags = (text) => {
  if (!text) {
    return "";
  }
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

const validationSchema = Yup.object({
  title: Yup.string().required("Required"),
  short_text: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  package_price: Yup.number().required("Required"),
  membership_type: Yup.string().required("Required"),
  duration: Yup.number().required("Required"),
  duration_type: Yup.string().required("Required"),
  return_policy: Yup.string().required("Required"),
  no_of_users: Yup.string().required("Required"),
});

export const PlansEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [planData, setPlanData] = useState({ ...initialValues });
  const [fetchDescription , setFetchDescription ] = useState("")
  const { setErrorToaster, setSuccessToaster } = useToaster();
  const descriptionRef =  useRef(null)

  const handlePlanDetails = (details) => {
    const updateData = {
      title: stripHtmlTags(details?.title),
      short_text: stripHtmlTags(details?.short_text),
      no_of_users: details?.no_of_users,
      package_price: details?.package_price,
      duration: details?.duration,
      description: details?.description || "",
      return_policy: details?.return_policy,
      membership_type: details?.membership_type,
      duration_type: details?.duration_type,
    };
    setPlanData(updateData);
    // setFetchDescription(details?.description || "");
  };

  const fetchPlansDetail = async () => {
    try {
      const response = await api.get(`/${MEMBERSHIPALL}/${params.id}`);
      const details = response?.data?.data?.membershipPlans;
      if (details) {
        handlePlanDetails(details);
        console.log("fetchPlansDetail:", details);
      }
    } catch (error) {
      console.log("fetchPlansDetail Error:", error);
    }
  };

  useEffect(() => {
    if(params?.id) {
    fetchPlansDetail();
    }
  }, []);

  const handleEditSubmit = async (values) => {
    let upd = {
      ...values,
      // description: fetchDescription,
    };
    if(params?.id) {
      try {
        const response = await api.post(`/${MEMBERSHIPALL}/${params?.id}`, upd);
        console.log("res:", response?.data?.data?.Membership_plan);
        fetchPlansDetail();
        setSuccessToaster("Succesfully Updated");
        navigate("/showplansall");
      } catch (error) {
        let det = error?.response?.data?.message;
        console.log("handleEditSubmit Error:", det);
        setErrorToaster(det);
      }
    } else {
      try {
        
        const response = await api.post(`/${MEMBERSHIPALL}`, upd);
        navigate("/showplansall");
        setSuccessToaster(response?.data?.message);
        console.log("handleAddPlan :", response);
      } catch (error) {
        setErrorToaster(error?.response?.data?.message);
        console.log("handleAddPlan Error:", error);
      }
    }
    
  };

  return (
    <Container>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/showplansall">Plans</Link>
              </li>
              <li class="breadcrumb-item active ">{params?.id ? "Edit" : "Add"} plan </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12">
              {/* <input type="file" accept="image/*" /> */}
              <Formik
                initialValues={planData}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                  // console.log("values:", values);
                  handleEditSubmit(values);
                }}
              >
                {({ values,errors, setFieldValue }) => (
                  <Form>
                    {console.log("lodg:",values)}
                    {console.log("errors",errors)}
                    <div className="card mb-4">
                      <h5 className="card-header">{params?.id ? "Update" : "Add"} plan details</h5>
                      {/* <div className="card-body">
                        <div className="d-flex align-items-start align-items-sm-center gap-4">
                          <img
                            src="../../assets/img/avatars/5.png"
                            alt="usr-avatar"
                            className="d-block w-px-100 h-px-100 rounded"
                            id="uploadedAvatar"
                          />
                          <div className="button-wrapper">
                            <label
                              for="upload"
                              className="btn btn-primary me-2 mb-3"
                              tabIndex="0"
                            >
                              <span className="d-none d-sm-block">
                                Upload new photo
                              </span>
                            </label>
                          </div>
                        </div>
                      </div> */}

                      <hr className="my-0" />

                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col-md-4 col-12">
                            <label className="form-label">Title</label>
                            <Field
                              type="text"
                              className={`form-control`}
                              placeholder="Title"
                              name="title"
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-8 col-12">
                            <label className="form-label">Short text</label>
                            <Field
                              type="text"
                              className={`form-control`}
                              placeholder="Short Text"
                              name="short_text"
                            />
                            <ErrorMessage
                              name="short_text"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-4 col-12">
                            <label className="form-label">No of users</label>
                            <Field
                              type="text"
                              className={`form-control`}
                              placeholder="No of users"
                              name="no_of_users"
                            />
                            <ErrorMessage
                              name="no_of_users"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-4 col-12">
                            <label className="form-label">Package price</label>
                            <Field
                              type="number"
                              min="0"
                              className={`form-control`}
                              placeholder="Package Price"
                              name="package_price"
                            />
                            <ErrorMessage
                              name="package_price"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-4 col-12">
                            <label className="form-label">Duration</label>
                            <Field
                              type="number"
                              min="0"
                              className={`form-control`}
                              placeholder="Duration"
                              name="duration"
                            />
                            <ErrorMessage
                              name="duration"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label className="form-label">Description</label>
                              <JoditEditor
                              value={values?.description}
                               name="description"
                              onChange={(newContent) => {
                              //  setFetchDescription(newContent)
                                setFieldValue("description",newContent)
                                //descriptionRef.current.focus()
                              }}
                            className="joditeditor"

                            />                          
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-form"
                          />
                        </div>
                        
                        <div className="row mb-3">
                          <label className="form-label">Refund policy</label>
                          <Field
                            as="textarea"
                            className="form-control"
                            rows="3"
                            placeholder="Refund Policy"
                            name="return_policy"
                          />
                          <ErrorMessage
                            name="return_policy"
                            component="div"
                            className="invalid-form"
                          />
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6 col-12">
                            <label className="form-label">Memership type</label>
                            <Field
                              as="select"
                              name="membership_type"
                              className="select2 form-select"
                            >
                              <option value="">Select</option>
                              <option value="Individual">Individual</option>
                              <option value="Family">Family</option>
                              <option value="Corporate">Corporate</option>
                            </Field>
                            <ErrorMessage
                              name="membership_type"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <label className="form-label">Duration type</label>
                            <Field
                              name="duration_type"
                              as="select"
                              className="select2 form-select"
                            >
                              <option value="">Select</option>
                              <option value="Days">Days</option>
                              <option value="Months">Months</option>
                              <option value="Years">Years</option>
                            </Field>
                            <ErrorMessage
                              name="duration_type"
                              component="div"
                              className="invalid-form"
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
